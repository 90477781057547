:root {
  --background: #111331;
  --background-container: #0a0b20;
  --foreground: #ff0000;
  --text-header: #000000;
  --text-nav: #fafafa;
  --text-foot: #808080;
  --text-body: #ffffff;
  --fontsize-main: 1.2em;
}

.Main {
  background-color: var(--background);
  min-height: 100vh;
}

body {
  color: var(--text-body);
}

header {
  margin: 0px;
  color: var(--text-header);
  background-color: var(--foreground);
  background-image: url("./image/img_out1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 1vmin 0 2vmin;
  display: flex;
  min-height: 12vw;
}

header h1 {
  margin: 0px;
  padding: 0px;
  font-size: 3em;
  text-shadow: 0 0 18px #ffffff;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 12px 12px 0px 0px;
  padding: 0px;
  background-color: var(--background);
}

nav a {
  padding: 0px 1em;
  font-size: 1.6em;
  color: var(--text-nav);
  text-decoration: none;
}

.RouteContainer {
  margin: 0 0 0.2em;
  background-color: var(--foreground);
  color: var(--text-nav);
  min-height: 80vh;
  padding: 2vmin;
}

.container {
  margin: 1vw;
}

.container p {
  margin: 0 1vw;
  padding: 1vw;
  background-color: var(--background-container);
  border-radius: 6px;
  font-size: var(--fontsize-main);
}

.home h1 {
  text-align: center;
}

.about {
  display: flex;
  padding-top: 1%;
}

.contact-container {
  float: right;
  background-color: var(--background-container);
  border-radius: 6px;
  min-width: 40vw;
  padding: 1vw;
  display: grid;
}

.contact-container h2 {
  grid-column-start: 1;
}

.contact-list {
  grid-column-start: 1;
  grid-column-end: 1;
  margin-right: 1vw;
}

.contact-list li {
  list-style: none;
}

.contact-map {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  max-width: 100%;
  overflow: hidden;
  min-width: 20vw;
  min-height: 20vw;
}

#google-maps-canvas {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

#google-maps-canvas iframe {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

footer {
  text-align: center;
  font-size: 0.8em;
  color: var(--text-foot);
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 24px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px); 

  transition: opacity 250ms ease-out, transform 300ms ease;
}
/* Responsive conditions */
@media only screen and (max-width: 1024px) {
  nav {
    margin: 0px;
    align-self: center;
  }
  header {
    flex-direction: column;
    text-align: center;
  }
  .RouteContainer {
    margin: 1vmin 1vw;
  }
}